import './App.css';
import logo from './devotion.webp';
import {GooglePlayButton, AppStoreButton, ButtonsContainer,} from "react-mobile-app-button";;

function App() {
  const iOSUrl = "https://apps.apple.com/us/app/devotion-lgbt/id1585197000";
  const playStoreUrl = "https://play.google.com/store/apps/details?id=com.crowdbotics.devotion";
  
  return (
    <div className="App">
      <header className="App-header">
        <p className="devotion">
        D E V O T I O N 
        </p>
        <img src={logo} className="App-logo" alt="logo" />
        <div>
        <ButtonsContainer>
          <GooglePlayButton
            theme="dark"
            url={playStoreUrl}
            className={"custom-style"}
          />
          <AppStoreButton
            theme="dark"
            url={iOSUrl}
            className={"custom-style"}
          />
        </ButtonsContainer>
        </div>
      </header>
    </div>
  );
}

export default App;
